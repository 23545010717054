<template>
    <div>
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="formatedItems"
            :fields="fields"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="tableText.itemsPerPage"
            hover
            small
            sorter
            pagination
          >
          <template #checked-filter>
            <td style="border-top:none" class="text-center">
              <CInputCheckbox
                custom
                :label="$t('label.all')"
                class="checkbook-filter mr-2"
                :checked="selectedAllModulo"
                @update:checked="selectAllModulos($event)"
                
              />
            </td>
          </template>
          <template #checked="{item}">
            <td class="text-center">
              <CInputCheckbox
                custom
                class="checkbook-filter ml-1"
                :checked="item.checked"
                @update:checked="addRemoveId(item)"
              />
            </td>
          </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <CCol sm="12" class="text-right ml-3">
        <CButton color="add" :disabled="isSubmit" @click="sendData()">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
      </CCol>
    </div>
    </template>
    
    <script>
    import General from "@/_mixins/general";
    import { mapState } from "vuex";
    
    //DATA
    function data() {
      return {
        items: [],
        Loading:  false,
        arrIdsOrigin: [],
        arrIds:[],
        selectedModulo: [],
        selectedAllModulo: false,
        isSubmit: false
      };
    }
  
  //METHOD
  function selectAllModulos(event) {
    this.arrIds = [];
    if (event) {
      this.selectedAllModulo = true;
      //SELECCIONA TODOS LOS ITEM
      this.items.map((item) => {
        this.arrIds.push({id: item.YardViewId, idSelect: item.YardViewConfigId ? item.YardViewConfigId : ''});  
      });

    }else{
      this.selectedAllModulo = false;
    }
  }

  function addRemoveId(item) {

    let bool = this.arrIds.filter((e) => e.id == item.YardViewId)

    if( bool.length == 0 ){
      //ADD
      this.arrIds.push({id: item.YardViewId, idSelect: ''});
      
    }
    else{
      //DELETE
      this.arrIds = this.arrIds.filter((e) => e.id !== item.YardViewId);
    }
    if (this.arrIds.length == this.items.length) {
      this.selectedAllModulo = true;
    }else{
      this.selectedAllModulo = false;
    }
  }

  function getListViews() {
    this.Loading = true;
    this.$http
      .get('YardViewConfig-list',{ YardId: this.getYardId})
      .then(response => {
        let listado = response.data.data;
        if (listado && listado.length != 0){
          this.items = listado;
          this.items.map(item =>{
            if (item.YardViewConfigId) {
              this.arrIdsOrigin.push({id: item.YardViewId, idSelect: item.YardViewConfigId});
            }
          })
        }
        listado.map((item) => {
          if ( item.YardViewConfigId !== null ) {
            this.arrIds.push({id: item.YardViewId, idSelect: item.YardViewConfigId});
          }
        });
        if (this.arrIds.length == this.items.length) {
          this.selectedAllModulo = true;
        }else{
          this.selectedAllModulo = false;
        }
      })        
      .finally(() => {
        this.Loading = false;
      });
  }

  function sendData(){
    let res = [];
    this.Loading = true;
    let Json = [];
    let Total_Id = [];

    Total_Id = [...this.arrIdsOrigin, ...this.arrIds];
    Total_Id.map(item =>{
      if (!Json.some(TpCargo => TpCargo.id == item.id )) {
        Json.push(item);
      }
    })

    let YardViewConfigJson = Json.map((item) => {
      let ValidateCurrentCargo = this.arrIds.some(e =>e.id == item.id)
        return {
          YardId: this.getYardId,
          YardViewConfigId:  item.idSelect ? item.idSelect : '',
          YardViewId: item.id,
          Status: ValidateCurrentCargo ? 1 : 0,
        };
    })

    this.$http.post("YardViewConfig-insert", YardViewConfigJson, { root: 'YardViewConfigJson' })
      .then((response) => {
        res = [...response.data.data];

        this.getYards();

        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: res[0].Response,
          type: 'success',
        });

        this.getListViews();
        this.Loading = false;

        this.cleanDataSelect();
      })
      .catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: 'error',
        });
        this.Loading = false;
      });
  }

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.$store.state.auth.branch.CompanyBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.lenght != 0){
        let yardItem = listado.find((item)=> item.YardId === this.$store.state.yard.yardSelected.YardId);

        if(yardItem)
          this.$store.state.yard.yardSelected = yardItem;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

  function resetDataTab(){
    this.items = [];
  }

  function cleanDataSelect(){
    this.arrIds = [];
    this.items = [];
    this.selectedModulo
    this.selectedAllModulo = false;
  }
 
  // COMPUTED
  function fields() {
    return [
      { key: "Nro",label: "#", _style: "width:1%;",filter: false,},
      { key: "YardViewName", label: this.$t("label.views"),_style: 'width:30%;',_classes:'text-center',},
      { key: "YardViewDs", label: this.$t("label.description"),_style: 'width:30%;',_classes:'text-center'},
      { key: 'checked',label: this.$t('agridTable.applyFilter'),sorter: false, filter: false,_style:'width:3%;',_classes:'text-center'},   
    ];
  }

  function formatedItems() {
    let _lang = this.$i18n.locale;
    if (this.items.length > 0) {
      return this.items.map((item) => {

        let checkeds = this.arrIds.filter((e) => e.id == item.YardViewId);

        return {
          ...item,
          Nro: item.Nro,
          YardViewName: _lang == 'en' ? item.YardViewNameEn : item.YardViewNameEs,
          YardViewDs:  _lang == 'en' ? item.YardViewDsEn : item.YardViewDsEs,
          checked: (checkeds.length > 0),
        };
      });
    }
  }

  function getYardId() {
      return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
  }
  export default {
    name: "index",
    mixins: [General],
    props: {Tab: Number},
    data,
    components: {},
    methods: {
      selectAllModulos,
      addRemoveId,
      getListViews,
      sendData,
      resetDataTab,
      cleanDataSelect,
      getYards
    },
    computed: {
      fields,
      formatedItems,
      getYardId,
      ...mapState({
        activeTab: state => state.yard.tabIndex,
        yardSelected: state => state.yard.yardSelected
      }),
    },
    watch: {
      activeTab: function (newTab) {
        if (newTab == 7) {
          this.getListViews();
          this.resetDataTab();
          this.cleanDataSelect();
        }
      },
    },
  };
  </script>
  <style scoped>
  </style>